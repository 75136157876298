import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { AuthService } from '@services/auth-service/auth.service';
import { NotificationService } from '@services/notification-service/notification.service';
import { StorageService } from '@services/storage-service/storage.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  environment = environment;

  isLogin = true;
  isVerify = false;
  @Input() disableRegister = false;
  @Input() isModal = false;
  @Output('onDismissLoginForm') dismissLoginForm = new EventEmitter<boolean>();

  constructor(
    private modalController: ModalController,
    public notificationService: NotificationService,
    private utils: UtilsService,
    private storageService: StorageService,
    public purchaseService: PurchaseService,
    private authService: AuthService,
    private onboardingService: OnboardingService,
  ) {

  }

  changeSignMethod(method: boolean): void {
    this.isLogin = method;
  }

  dismissForm() {
    this.dismissLoginForm.emit(true);
  }

  showVerifyCode(ev) {
    this.isVerify = true;
  }

  hideVerifyCode(ev) {
    this.isVerify = false;
  }

  successfulLogin(event: any) {
    console.log("login 3")
    if (this.authService.couponDeepLink) {
      this.modalController.dismiss();
      this.authService.couponDeepLink = false;
    }
    this.authService.trackAccountCreated();
    setTimeout(async () => {
      if (this.authService.newAccount) {
        await this.storageService.storeInitUserDataOnServer().then(async () => {
          await this.storageService.importTrainingDataToServer();
          this.authService.loadUserData();
        });
      } this.authService.newAccount = false
    }, 500);
    if (this.utils.isWebVersion && !this.authService.couponDeepLink) this.notificationService.OneSignalWeb();
    if (this.onboardingService.isActive) this.onboardingService.didRegister = true;
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}