import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '@services/auth-service/auth.service';
import { LanguageService } from '@services/language-service/language.service';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { SettingsService } from '@services/settings-service/settings.service';
//import { environment } from 'environments/environment';
import { timer } from 'rxjs';
import { Device } from '@capacitor/core';
import { environment } from 'environments/environment';
import { NotificationService } from '@services/notification-service/notification.service';
import { StorageService } from '@services/storage-service/storage.service';

const VIRTUAL_REQEUST_WAIT_TIME = 300;

@Component({
  selector: 'login-small',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {

  @Output() closePane = new EventEmitter<any>();
  @Output() successLogin = new EventEmitter<any>();
  @Output() openPane = new EventEmitter<any>();
  environment = environment;
  isLoginWithMailPending: boolean = false;
  isLoginWithMailError: boolean = false;
  isLoginSuccess: boolean = false;
  loginForm: FormGroup;

  loginSuccessTitle: string = 'Welcome [name]';
  loginSuccessText: string = 'Give us a moment to load your personal data and create your personal learning experience to learn [language].';

  constructor(
    private authService: AuthService,
    public platform: Platform,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private navCtrl: NavController,
    public settingsService: SettingsService,
    public languageService: LanguageService,
    public onboardingService: OnboardingService,
    public storageService: StorageService,
    public notificationService: NotificationService,
    private alertController: AlertController
  ) {
    this.loginForm = this.formBuilder.group(
      {
        email: [''],
        // password: [''],
      }
    );
  }

  ngOnInit() {
    this.settingsService.eventLoginPaneViewChange.subscribe((view) => {
      console.log('view', view);
    });
  }


  loginFacebook() {
    this.authService.loginWithFacebook().then(success => {
      this.emitSuccessLogin(success);
      this.authService.signupMethod = 'facebook-sso'
    }).catch(error => {
      if (error.error.tooManyDevices) {
        this.showAlertToManyDevices(error.error.tmpToken);
        this.closePane.emit();
      }
    });
  }

  loginGoogle() {
    this.authService.loginWithGoogle().then(success => {
      this.emitSuccessLogin(success);
      this.authService.signupMethod = 'google-sso'
    }).catch(error => {
      if (error.error.tooManyDevices) {
        this.showAlertToManyDevices(error.error.tmpToken);
        this.closePane.emit();
      }
    });
  }

  loginApple() {
    this.authService.loginWithApple().then(success => {
      this.emitSuccessLogin(success);
      this.authService.signupMethod = 'apple-sso'
    }).catch(error => {
      if (error.error.tooManyDevices) {
        this.showAlertToManyDevices(error.error.tmpToken);
        this.closePane.emit();
      }
    });
  }

  loginMail() {
    console.log('loginMail')
    this.settingsService.eventLoginPaneViewChange.next('local');
    // this.settingsService.loginPane.moveToBreak('top');
    this.settingsService.isLoginWithMail = true;
    this.settingsService.loginPaneView = 'local';
  }

  loginSocial() {
    this.settingsService.isLoginWithMail = false;
    this.settingsService.loginPaneView = 'social';
    this.settingsService.loginPane.moveToBreak('middle')
  }

  continueLoginApple() {
    this.closePane.emit();
    this.alertController.create({
      header: this.languageService.label.txt_apple_login_modal_headine,
      message: this.languageService.label.txt_apple_login_modal_txt,
      cssClass: this.languageService.label.txt_apple_login_modal_confirm,
      buttons: [
        {
          text: this.languageService.label.txt_already_subscribed_confirm,
          handler: () => {
            timer(1000).subscribe(() => { this.openPane.emit(); });
            this.loginApple();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  emitClosePane() {
    this.closePane.emit();
  }

  emitSuccessLogin(data: any) {
    try {
      this.isLoginSuccess = true;
      setTimeout(async () => {
        this.isLoginSuccess = false;
      }, 8000);
      this.isLoginWithMailError = false;

      this.loginSuccessTitle = this.generateTitle(data);
      this.loginSuccessText = this.generateText(data);
      this.successLogin.emit(data);
      this.authService.trackAccountCreated();
      this.storageService.sendRevenueCatAttributes();
      setTimeout(async () => {
        if (this.authService.newAccount) {
          await this.storageService.storeInitUserDataOnServer().then(async () => {
            await this.storageService.importTrainingDataToServer();
            this.storageService.initUserDataFromServer();
            this.authService.loadUserData();

          });
        } this.authService.newAccount = false;
      }, 500);
      if (!this.platform.is('cordova')) this.notificationService.OneSignalWeb();
    } catch (error) {
      console.log(JSON.stringify(error))
    }
  }

  async onSubmit() {

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo()
    } catch (error) {
      console.error("Requesting device info failed", error)
    }

    this.isLoginWithMailPending = true;
    this.http.post(`${environment.api}/user/login`, {
      username: this.loginForm.getRawValue().email,
      password: this.loginForm.getRawValue().password,
      deviceInfo: deviceInfo
    }).toPromise().then((res: any) => {
      timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
        this.isLoginWithMailPending = false;
        this.navCtrl.navigateForward('/create-account?verify=true&email=' + encodeURIComponent(this.loginForm.getRawValue().email));
        this.emitClosePane();
        if (res.token) this.authService.setToken(res.token);
        if (res.user) this.authService.setUser(res.user);
        this.authService.setAnonymousUserId(res.anonymousUserId);
        const loggedIn = setInterval(() => {
          if (this.authService.user) {
            this.emitSuccessLogin(res);
            clearInterval(loggedIn);
            console.log('User logged in');

          }
        }, 500);
        // this.emitSuccessLogin(res);
      });
    }).catch(error => {
      /**
       * If user is not verified, redirect to create account page
       * and prefill email
       */
      // if (error.error.notVerified) {
      //   timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
      //     this.isLoginWithMailPending = false;
      //     this.emitClosePane();
      //     this.navCtrl.navigateForward('/create-account?verify=true&email=' + encodeURIComponent(this.loginForm.getRawValue().email));
      //   })
      //   return;
      // }
      console.log("DEBUG_AUTH_ERROR", JSON.stringify(error));

      if (error.error.tooManyDevices) {
        timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
          this.isLoginWithMailPending = false;
          this.emitClosePane();
          this.showAlertToManyDevices(error.error.tmpToken);
        })
        return;
      }

      timer(VIRTUAL_REQEUST_WAIT_TIME).subscribe(() => {
        this.isLoginWithMailPending = false;
        this.isLoginWithMailError = true;
      });
    })
  }

  forgotPassword() {
    this.emitClosePane();
    this.navCtrl.navigateForward('/reset-password');
  }

  createAccount() {
    this.emitClosePane();
    this.navCtrl.navigateForward('/create-account');
  }

  generateTitle(data: any): string {
    if (!data && environment.single) return this.languageService.label.txt_login_modal_success_title_alt_readle;
    if (!data && !environment.single) return this.languageService.label.txt_login_modal_success_title_alt;
    if (data.firstName) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${data.firstName}</span>`);
    }

    if (data.name) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${data.name}</span>`);
    }
    if (environment.single) return this.languageService.label.txt_login_modal_success_title_alt_readle; // welcome back to readle
    else return this.languageService.label.txt_login_modal_success_title_alt; // welcome back to langster
  }

  generateText(data: any): string {
    if (!data) return this.languageService.label.txt_login_modal_success_text_alt;
    if (data.selectedLanguage) {
      return this.languageService.label.txt_login_modal_success_text.replace('[language]', `<span>${this.getLanguageName(data.language)}</span>`);
    } else {
      return this.languageService.label.txt_login_modal_success_text_alt;
    }
  }

  getLanguageName(language: string): string {
    if (!language) return '';
    if (language === 'en' || language == 'eng') return this.languageService.label.txt_language_select_en;
    if (language === 'es') return this.languageService.label.txt_language_select_es;
    if (language === 'fr') return this.languageService.label.txt_language_select_fr;
    if (language === 'de') return this.languageService.label.txt_language_select_de;
    if (language === 'ja') return this.languageService.label.txt_language_select_ja;
    if (language === 'zh-hans') return this.languageService.label.txt_language_select_zh_hans;
  }

  showAlertToManyDevices(tmpToken) {
    this.alertController.create({
      header: this.languageService.label.txt_device_limit_reached_title,
      message: this.languageService.label.txt_device_limit_reached_text,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_yes,
          role: 'confirm',
          cssClass: 'secondary',
          handler: () => {
            this.authService.removeOldestDevice(tmpToken).then((data) => {
              this.showRemoveOldestDeviceSuccessAlert();
            });
          }
        },
        {
          text: this.languageService.label.txt_device_limit_reached_no,
          role: 'cancel',
          cssClass: 'primary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  async showRemoveOldestDeviceSuccessAlert() {
    this.alertController.create({
      message: this.languageService.label.txt_device_limit_reached_logout_success,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_logout_success_confirm,
          handler: () => {
            // this.logout();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    })
  }

}
