import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AuthService } from '@services/auth-service/auth.service';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { environment } from 'environments/environment';
import { Device } from '@capacitor/core'
import { NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss'],
})
export class ValidationCodeComponent implements OnInit {

  inputElements;
  isPending: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;
  isResendError: boolean = false;
  isResendPending: boolean = false;
  isResendDisabled: boolean = false;
  isResendSuccess: boolean = false;
  timer = 59;
  tmp_email;

  @Output('onBack') back = new EventEmitter<boolean>();
  @Output('onVerifySuccess') verifySuccess = new EventEmitter<boolean>();
  @Output('onErrorDevice') tooManyDevices = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public languageService: LanguageService,
    public purchaseService: PurchaseService,
    private storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalytics,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private platform: Platform
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params && params.email) {
        this.tmp_email = params.email;
      }
    });

    this.inputElements = document.querySelectorAll('input.code-input');

    this.inputElements.forEach((ele, index) => {
      ele.addEventListener('keydown', (e) => {
        // if the keycode is backspace & the current field is empty
        // focus the input before the current. Then the event happens
        // which will clear the "before" input box.
        if (e.keyCode === 8 && e.target.value === '') this.inputElements[Math.max(0, index - 1)].focus()
      })
      ele.addEventListener('input', (e) => {
        // take the first character of the input
        // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
        // but I'm willing to overlook insane security code practices.
        const [first, ...rest] = e.target.value
        e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index === this.inputElements.length - 1
        const didInsertContent = first !== undefined
        if (didInsertContent && !lastInputBox) {
          // continue to input the rest of the string
          this.inputElements[index + 1].focus()
          this.inputElements[index + 1].value = rest.join('')
          this.inputElements[index + 1].dispatchEvent(new Event('input'))
        }
      })
    })


  }

  async onSubmit(e) {
    e.preventDefault();

    if (!this.authService.user && !this.tmp_email) {
      this.isError = true;
      return
    };
    const code = Array.from(this.inputElements).map((el: any) => el.value).join("");

    if (code.length !== 6) {
      this.isPending = false;
      return;
    }

    this.isPending = true;

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo();
    } catch (error) {
      console.log("Error getting device info", error);
    }
    this.authService.NewUserCheck();
    if (this.authService.user?.email.includes('privaterelay.appleid.com') || (this.tmp_email && this.tmp_email.includes('icloud')) && this.authService.newAccount) {
      console.log("apple verify", this.authService.signupMethod, this.authService.user?.email, this.tmp_email, JSON.stringify(this.authService.user));
      this.http.post(`${environment.api}/user/register/app`, {
        secondaryEmail: this.authService.user?.email ? this.authService.user?.email : this.tmp_email,
        email: this.authService.secondaryEmail,
      }).subscribe(async (res: any) => { // Verify success -> successfully created account

        console.log("apple verify success", this.authService.user, JSON.stringify(res));
        this.authService.setUser(res.user);
        this.authService.setToken(res.token);

      }, (err) => {
        this.isError = true;
        this.isPending = false;

        this.inputElements = document.querySelectorAll('input.code-input');

        // loop inputElements and clear all values
        this.inputElements.forEach((ele, index) => {
          ele.value = '';
        })

        // focus first input
        this.inputElements[0].focus();

      })
    } else {
      console.log("verify", this.authService.signupMethod, this.authService.user?.email, this.tmp_email, JSON.stringify(this.authService.user));
      this.http.post(`${environment.api}/user/verify`, {
        code: code,
        email: this.authService.user?.email ? this.authService.user?.email : this.tmp_email,
        deviceInfo: deviceInfo,
        native: this.platform.is('cordova')
      }).subscribe(async (res: any) => { // Verify success -> successfully created account
        await this.authService.setUser(res.user);
        await this.authService.setToken(res.token);
        console.log("verify success", this.authService.user, JSON.stringify(res.user, res.token));
        this.storageService.sendRevenueCatAttributes();
        this.isSuccess = true;
        this.isPending = false;
        this.purchaseService.switchUserAccount().then(() => {
          console.log("User account switched")
        }).catch((err) => {
          console.log("Error switching user account", err)
        })
        this.authService.NewUserCheck()
        if (this.authService.newAccount) {
          this.firebaseAnalytics.setCurrentScreen('screen_onboarding_create_account_thankyou ');

          await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
          if (!this.authService.user.data || this.authService.user.data?.length == 0 || (this.authService.user.data && !this.authService.user.data[0].training)) {
            await this.storageService.importTrainingDataToServer();
          }
          await this.authService.loadUserData();
          await this.storageService.initUserDataFromServer();
          // Store training data
        }

        await this.authService.loadUserData(); //reload userdata fetch from server
      }, (err) => {
        console.log("verify error", err, this.authService.token);
        if (err.error.tooManyDevices) this.authService.tmpErrToken = err.error.tmpToken;
        this.isError = true;
        this.isPending = false;
        this.goBack()
        this.tooManyDevices.emit(true);

        this.inputElements = document.querySelectorAll('input.code-input');

        // loop inputElements and clear all values
        this.inputElements.forEach((ele, index) => {
          ele.value = '';
        })

        // focus first input
        this.inputElements[0].focus();

      })
    }


  }

  goBack() {
    // this.back.emit()
    this.navCtrl.back();
  }

  WelcomeMsg() {
    if (!this.authService.user) return this.languageService.label.txt_login_modal_success_title_alt;
    if (this.authService.user.firstName) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${this.authService.user.firstName}</span>`);
    }

    if (this.authService.user.name) {
      return this.languageService.label.txt_login_modal_success_title.replace('[name]', `<span>${this.authService.user.name}</span>`);
    }

    return this.languageService.label.txt_login_modal_success_title_alt; // welcome back to langster
  }

  resendVerificationCode() {
    const email = this.authService.user?.email ? this.authService.user?.email : this.tmp_email;
    console.log("resendVerificationCode", email, this.authService.user?.email, this.tmp_email);

    this.isResendPending = true;
    console.log("resendVerificationCode", this.authService.user?.email);
    this.http.post(`${environment.api}/user/resend-verification`, {
      email: email
    }).subscribe((res: any) => {
      this.isResendError = false;
      this.isResendPending = false;
      this.isResendSuccess = true;
    }, (err) => {
      console.log("resendVerificationCode error", err);
      if (err.error.error == 'timeout') {
        this.isResendDisabled = true;
        this.isResendError = false;
        this.isResendSuccess = false;
        this.timerCountdown();
        return;
      }

      this.isResendError = true;
      this.isResendPending = false;
      this.isResendSuccess = false;
    })
  }

  continue() {
    this.verifySuccess.emit(true);
  }

  timerCountdown() {
    if (this.timer > 0) {
      this.timer--;
      setTimeout(() => {
        this.timerCountdown();
      }, 1000);
    } else {
      this.isResendDisabled = false;
      this.timer = 60;
    }
  }
}
