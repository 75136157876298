import { Component, Input, EventEmitter, Output } from '@angular/core';
import Word from '@interfaces/word';
import { AudioServiceService } from '@services/audio-service/audio-service.service';
import { LanguageService } from '@services/language-service/language.service';
import { SettingsService } from '@services/settings-service/settings.service';
import { WordService } from '@services/word-service/word.service';

@Component({
  selector: 'story-word-detail',
  templateUrl: './story-word-detail.component.html',
  styleUrls: ['./story-word-detail.component.scss'],
})
export class StoryWordDetailComponent {


  @Input() word: Word;
  @Output("viewFullWord") viewFullWord: EventEmitter<any> = new EventEmitter();
  @Output("stopAudioReading") stopAudioReading: EventEmitter<any> = new EventEmitter();
  @Output("addToTraining") addToTraining: EventEmitter<any> = new EventEmitter();

  isAudioActive: boolean;
  translation = '';
  plural = '';
  isVerb: boolean;
  isAdjective: boolean;
  hasForm: boolean = true;

  constructor(
    public languageService: LanguageService,
    public wordService: WordService,
    public audioService: AudioServiceService,
    public settingsService: SettingsService,
  ) { }

  ngOnChanges(changes: any): void {
    this.hasForm = true;
    if (!this.word) return;
    if ((this.word.display.includes('"') || this.word.display.includes('“') || this.word.display.includes('。')) && (this.languageService.origin == 'ja' || this.languageService.origin == 'zh-hans')) {
      console.log(this.word.display)
      const pos1 = this.word.display.indexOf('"');
      const pos2 = this.word.display.indexOf('“');
      const pos3 = this.word.display.indexOf('。');
      const pos = Math.min(Math.min(pos1, pos2), pos3)
      this.word.display = this.word.display.slice(0, pos)
      console.log(this.word.display)
    }
    if (this.word.category === 'verb') this.isVerb = true;
    else this.isVerb = false;
    if (this.word.category === 'adjectives') this.isAdjective = true;
    else this.isAdjective = false;
    if (this.languageService.origin == 'ja' && this.isAdjective) {
      const wordForm: any = this.word?.forms
      let formHit = 0;
      for (let i = 0; i < wordForm.length; i++) {
        if (!wordForm[i].kanji) {
          formHit++;
        }
      }
      if (formHit == wordForm.length) {
        this.hasForm = false;
      }
    }
  }

  playAudio(): void {
    this.stopAudioReading.emit(false);
    this.audioService.initNewAudio(this.word.audiofile, true);

    this.isAudioActive = true;
    setTimeout(() => {
      this.isAudioActive = false;
    }, 2000);
  }
}
