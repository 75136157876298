import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ModalController, IonRouterOutlet, Platform } from '@ionic/angular';
import { LanguageService } from '../../service/language-service/language.service';
import { ProductIdType, PurchaseService } from '../../service/purchase-service/purchase.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { UtilsService } from '@services/utils-service/utils.service';
import { Product, ShopPage } from '../shop/shop.page';
import { environment } from 'environments/environment';
import { PurchaseOrigin } from 'app/enums/purchase_origin.enum';
import 'firebase/analytics';
import { AuthService } from '@services/auth-service/auth.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TransformCurrencyAmountPipe } from '@pipes/transform-currency-amount.pipe';
import { CreditCardPurchasePrice } from '@pipes/credit-card-purchase-price.pipe';
import { Router } from '@angular/router';
import { LoginPage } from '@pages/login/login.page';
import { StorageService } from '@services/storage-service/storage.service';
import { NotificationService } from '@services/notification-service/notification.service';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


declare const Stripe;

type IntentType = 'setup' | 'payment';

@Component({
  selector: 'app-credit-card-purchase',
  templateUrl: './credit-card-purchase.page.html',
  styleUrls: ['./credit-card-purchase.page.scss'],
})
export class CreditCardPurchasePage {
  closePromoModal = true;
  card: any;
  secret: any;
  stripe = Stripe(environment.stripe.key);
  elements;
  emailAddress: string;
  isNarrow = false;
  isMobile = false;
  isReady = false;
  isStripeElementsReady = false;
  isPaymentSuccess = false;
  couponCode = "";
  isCouponCodeValid = false;
  isCouponCodeInvalid = false;
  isCouponCodeLoading = false;
  closeSkipPromoModal = true;
  couponObject;
  totalPrice;
  discount;
  loginShown = false;
  loading = true;
  priceNum;
  tempPrice;
  purchaseError = false;
  loginPromoModal = false;

  usedTrial = false;

  linkAuthenticationElement;
  paymentElement;
  product;
  clientSecret;
  pane;

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private navCtrl: NavController,
    public languageService: LanguageService,
    public purchaseService: PurchaseService,
    private firebaseAnalytics: FirebaseAnalytics,
    private modalController: ModalController,
    private platform: Platform,
    private routerOutlet: IonRouterOutlet,
    private router: Router,
    public utilsService: UtilsService,
    public authService: AuthService,
    public onboardingSerice: OnboardingService,
    private gtmService: GoogleTagManagerService,
    private http: HttpClient,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private transformCurrencyAmountPipe: TransformCurrencyAmountPipe,
    private creditCardPurchasePrice: CreditCardPurchasePrice
  ) {
    this.purchaseService.webappSelectedPlan$.subscribe((plan) => {
      this.isStripeElementsReady = false;
      this.linkAuthenticationElement.destroy();
      this.paymentElement.destroy();
      // this.initialize();
    });
    this.onResize();


    /**
     * Copy the value of the stripeUsedTrial variable from the user object
     * This is needed because the user object is not updated when the user uses the trial
     * To prevend instand change of the value when the user uses the trial, we copy the value
     */
    if (this.authService.user)
      console.log(this.authService.user, "logged")
    if (this.authService.user?.stripeUsedTrial) {
      const x = JSON.parse(JSON.stringify(this.authService.user?.stripeUsedTrial));
      if (x != undefined) {
        this.usedTrial = x;
      }
    }

  }

  async ionViewDidEnter(): Promise<void> {
    this.onboardingSerice.fromPromotion = false;
    this.purchaseService.skipTrial = false;
    this.loading = true;
    this.modalController.dismiss();
    if (this.purchaseService.getPurchaseOrigin() == PurchaseOrigin.NAVIGATION) {
      this.gtmService.pushTag({ event: 'enter_payment', origin: 'enter_payment_nav_bar' });
    } else if (this.purchaseService.getPurchaseOrigin() == PurchaseOrigin.READMORE) {
      this.gtmService.pushTag({ event: 'enter_payment', 'category': 'enter_payment_locked_content' });
    } else if (this.purchaseService.getPurchaseOrigin() == PurchaseOrigin.POPUP) {
      this.gtmService.pushTag({ event: 'enter_payment', origin: 'enter_payment_home_popup' });
    } else {
      this.gtmService.pushTag({ event: 'enter_payment', origin: 'enter_payment' });
    }
    this.firebaseAnalytics.setCurrentScreen('start_learning');
    // handler for stripe inputs
    document.querySelector("#payment-form").addEventListener("submit", (e) => {
      this.handleSubmit(e)
    });


    const checkPopupClosed = setInterval(async () => {

      // wait for product to be loaded before init stripe inputs
      await this.purchaseService._webProductYear$.pipe(takeUntil(this.ngUnsubscribe$)).toPromise();
      this.isReady = true;
      this.getProductToBuy();

      if (this.router.url.includes('code')) {
        this.activatedRoute.queryParams.subscribe(params => {
          if (params) {
            this.onboardingSerice.fromPromotion = true;
            if (params.codeMonthly) {
              this.couponCode = params.codeMonthly
              this.purchaseService.webappSelectedPlan = Product.Monthly
            } else if (params.codeYearly) {
              this.purchaseService.skipTrial = true;
              this.couponCode = params.codeYearly
              this.purchaseService.webappSelectedPlan = Product.Yearly
            } else if (params.codeLifetime) {
              this.couponCode = params.codeLifetime
              this.purchaseService.webappSelectedPlan = Product.Lifetime
            }
            this.purchaseService.setPurchaseOrigin(PurchaseOrigin.PROMO);

            this.getProductToBuy();
            this.resetPrices()
            this.applyCouponCode()
          }
        });
      }
      if (this.authService.user) {
        this.notificationService.OneSignalWeb();
        await this.initialize();// init stripe inputs
        if (this.purchaseService.userPremium == true) {
          this.navCtrl.navigateRoot("/home")
          this.authService.couponDeepLink = false;
        }
        clearInterval(checkPopupClosed);
      } else if (!this.loginShown) {
        this.modalLoginForPromo();
        this.authService.couponDeepLink = true;
      }
      this.loading = false;
    }, 500);
  }

  modalLoginForPromo() {
    this.loginPromoModal = true;
  }

  modalSkipPromo() {
    this.closeSkipPromoModal = false;
  }

  async initialize() {
    this.http.post(`${environment.api}/payments/${this.getPaymentIntentUrlByProductId(this.product.id)}`, {
      priceId: this.product.default_price.id,
      userId: this.authService.user.userId,
      email: this.authService.user.email,
      currency: this.purchaseService.getUserCurrency().toLowerCase(),
      forceUsedTrial: this.purchaseService.skipTrial
    }, {
      headers: this.authService.authHeader
    }).pipe(
      catchError(error => {
        // Check if the error status is 403
        if (error.status === 403) {
          // Perform your action here
          this.purchaseError = true;
        }
        // Rethrow the error or handle it differently
        return throwError(error);
      })
    ).subscribe((res: { clientSecret: string }) => {
      console.log("initialize", res)
      this.isStripeElementsReady = true;
      this.clientSecret = res.clientSecret;
      const appearance = { theme: 'flat' };
      this.elements = this.stripe.elements({ appearance, clientSecret: res.clientSecret });

      this.linkAuthenticationElement = this.elements.create("linkAuthentication");
      this.linkAuthenticationElement.mount("#link-authentication-element");

      this.linkAuthenticationElement.on('change', (event) => {
        this.emailAddress = event.value.email;
      });

      const paymentElementOptions = {
        layout: "tabs",
        wallets: {
          googlePay: 'auto',
          applePay: 'auto'
        },
        buttonType: {
          googlePay: 'buy',
          applePay: 'buy'
        },
      };

      this.paymentElement = this.elements.create("payment", paymentElementOptions);
      this.paymentElement.mount("#payment-element");
    });

    this.resetPrices()
  }

  private getIntent(productId: ProductIdType): IntentType {
    switch (productId) {
      case 'com.langster.universal.lifetime':
      case 'com_langster_universal_lifetime':
        return 'payment';
      case 'com_langster_universal_monthly':
      case 'com_langster_universal_monthly_now':
      case 'com_langster_universal_yearly':
        return 'setup'
      default:
        return 'setup'
    }
  }

  private getPaymentIntentUrlByProductId(productId: ProductIdType): string {
    const intent = this.getIntent(productId);
    if (intent !== 'payment') {
      return 'create-setup-intent';
    }
    return 'create-payment-intent';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1000) {
      this.isNarrow = true;
    } else {
      this.isNarrow = false;
    }
    if (window.innerWidth < 568) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.isPaymentSuccess) return; // Disable button after payment success

    this.setLoading(true);
    this.confirmPaymentStep();
  }

  confirmPaymentStep() {
    const intent = this.getIntent(this.product.id);
    if (intent !== 'payment') {
      this.stripe
        .confirmSetup(this.getConfirmationPayload())
        .then(async (result) => {
          this.handleIntentConfirmation(result);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.stripe
        .confirmPayment(this.getConfirmationPayload())
        .then(async (result) => {
          this.handleIntentConfirmation(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  private getConfirmationPayload() {
    return {
      elements: this.elements,
      redirect: 'if_required',
      confirmParams: {
        payment_method_data: {
          metadata: {
            priceId: this.product.default_price.id,
            userId: this.authService.user.userId,
            _id: this.authService.user._id,
            email: this.emailAddress,
            currency: this.purchaseService.getUserCurrency().toLowerCase(),
            product: this.product.id,
            promotionCode: this.couponObject?.code
          },
        },
      },
    }
  }

  private handleIntentConfirmation(result) {
    if (!result.error) {
      this.setLoading(false);
      if (this.authService.user.stripeUsedTrial || this.purchaseService.skipTrial) {
        this.purchaseService.firstPurchase = false;
      }
      this.authService.user.stripeUsedTrial = true;
      this.setSuccessButton();
      this.redirectAfterPayment(1300);
      this.purchaseService.makeUserPremium({});
    } else {
      this.setLoading(false);

      if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
        if (result.error.type == 'card_error') {
          this.showMessage(this.languageService.label.web_payment_screen_credit_card_error);
        } else if (result.error.type == 'validation_error') {
          this.showMessage(this.languageService.label.web_payment_screen_credit_validation_error);
        }
      } else {
        this.showMessage(this.languageService.label.web_payment_screen_credit_unexpected_error);
      }
    }
  }

  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      //@ts-ignore
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      //@ts-ignore
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }

  setSuccessButton() {
    this.isPaymentSuccess = true;
    document.querySelector("#submit").classList.add("success");
    document.querySelector("#button-text").textContent = this.languageService.label.web_payment_screen_credit_cta_subscription_success;
  }

  redirectAfterPayment(delay = 1300) {
    this.purchaseService.purchasedSession = true;
    timer(delay).subscribe(() => {
      if (this.product.id == 'com.langster.universal.lifetime' || this.product.id == 'com_langster_universal_lifetime') {
        this.authService.loadUserData().then((userData) => {
          if (userData?.stripeLifetimePurchase) {
            this.purchaseService.makeUserPremium({})
          }
        });
      }
      this.navCtrl.navigateRoot('/purchase');
    });
  }

  showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageText.textContent = "";
    }, 4000);
  }

  back(): void {
    this.purchaseService.purchaseScreenStatus = false;
    if (this.onboardingSerice.fromPromotion) {
      this.modalSkipPromo()
      return
    }
    if (this.authService.fromChoosePlan) this.navCtrl.back();
    else this.viewOtherPlans();
  }

  promoBack() {
    if (this.purchaseError) this.purchaseError = false;
    this.navCtrl.navigateRoot('/home');
  }

  async showLoginModal() {
    this.loginPromoModal = false;
    this.loginShown = true;
    this.modalController.create({
      component: LoginPage,
      cssClass: 'login-modal',
      backdropDismiss: false,
      componentProps: {
        'disableRegister': false,
        'isModal': false,
      }
    }).then((modal) => {
      modal.present();
    });
  }

  async successLogin(data: any) {
    console.log("successful login")
    const user = data.user;
    if (!user.userId) {
      await this.authService.storeUserData({ userId: this.authService.anonymousUserId });
      const u = user;
      u.userId = this.authService.anonymousUserId;
      user.userId = u.userId;
      this.authService.setUser(u);
    }
    this.firebaseAnalytics.setUserProperty("loggedin", 'true');
    this.authService.trackAccountCreated();
    setTimeout(async () => {
      if (this.authService.newAccount) {
        await this.storageService.storeInitUserDataOnServer().then(async () => {
          await this.storageService.importTrainingDataToServer();
          this.authService.loadUserData();
        });
      } this.authService.newAccount = false;
    }, 500);

    if (user.selectedLanguage) {
      try {
        this.languageService.setOrigin(user.selectedLanguage, false);
        this.storageService.storeUserLevel(user.selectedLevel, false);
        this.firebaseAnalytics.setUserProperty("level", user.selectedLevel);

        // Load complete user data after successful login
        this.authService.loadCompleteUserData(this.languageService.origin).then(async user => {
          this.authService.setUser(user)
          this.purchaseService.checkStripeSubscription(user);
          if (!this.authService.user.data || this.authService.user.data?.length == 0 || (this.authService.user.data && !this.authService.user.data[0].training)) {
            await this.storageService.importTrainingDataToServer();
          }
          this.storageService.initUserDataFromServer();
        })

        this.purchaseService.switchUserAccount().then(async () => {
          this.authService.couponDeepLink = false;
          this.storageService.sendRevenueCatAttributes();
          this.closeLoginModal();
          await this.initialize();
          if (this.purchaseService.userPremium) {
            timer(10).subscribe(() => {
              this.navCtrl.navigateRoot('/home');
            });
          }
        }).catch((err) => {
          console.error("There was an error configuring purchasing", err);
          this.closeLoginModal();
        });
      } catch (error) {
        console.log("❌❌❌❌❌", JSON.stringify(error));
      }
    } else {
      await this.initialize();
      this.closeLoginModal();
    }
    this.notificationService.OneSignalWeb();
  }

  closeLoginModal() {
    this.pane.hide();
    timer(1500).subscribe(() => {
      this.pane.destroy();
    })
  }

  async shopModal() {
    const mode = this.platform.is('ios') ? 'ios' : 'md';
    const modal = await this.modalController.create({
      component: ShopPage,
      cssClass: (!this.platform.is('cordova') && !environment.mockNative) ? 'shop-modal-web' : 'shop-modal',
      mode: mode,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
    });
    return await modal.present();
  }

  viewOtherPlans() {
    if (this.purchaseService.skipTrial) {
      this.navCtrl.navigateRoot('/home')
    } else {
      this.navCtrl.navigateBack('/home', {
        queryParams: {
          viewShop: true
        }
      });
    }

  }

  getProductToBuy() {
    if (this.purchaseService.webappSelectedPlan == Product.Yearly) {
      this.product = this.purchaseService.webProductYear;
    }
    else if (this.purchaseService.webappSelectedPlan == Product.Monthly) {
      this.product = this.purchaseService.webProductMonthly;
    }
    else if (this.purchaseService.webappSelectedPlan == Product.Lifetime) {
      this.product = this.purchaseService.webProductLifetime;
    }
  }

  // Will return the price_object id of the selected plan
  // If the user has selected a currency that is not supported by the plan, it will return the default price_object id
  // Lifetime is not considered here yet
  getSelectedPlanPriceId() {
    if (this.purchaseService.webappSelectedPlan == 'yearly') {
      const prod: StripeProduct = this.purchaseService.webProductYear;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency().toLowerCase()];
      if (localPrice) return localPrice.id;
      else return prod.default_price.id;
    }

    if (this.purchaseService.webappSelectedPlan == 'monthly') {
      const prod: StripeProduct = this.purchaseService.webProductMonthly;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency().toLowerCase()];
      if (localPrice) return localPrice.id;
      else return prod.default_price.id;
    }

    if (this.purchaseService.webappSelectedPlan == 'lifetime') {
      const prod: StripeProduct = this.purchaseService.webProductLifetime;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency().toLowerCase()];
      if (localPrice) return localPrice.id;
      else return prod.default_price.id;
    }
  }

  openModal() {
    this.closePromoModal = false;
  }

  closeModal() {
    if (!this.closeSkipPromoModal) {
      this.closeSkipPromoModal = true;
      return;
    } else this.closePromoModal = true;
    this.loginPromoModal = false
  }

  async resetPrices() {
    this.couponObject = null;
    this.discount = null;
    if (this.purchaseService.webappSelectedPlan == 'monthly') this.totalPrice = this.webMonthlyPrice;
    if (this.purchaseService.webappSelectedPlan == 'yearly') this.totalPrice = this.webYearlyPrice;
    if (this.purchaseService.webappSelectedPlan == 'lifetime') this.totalPrice = this.webLifetimePrice;
  }

  applyCouponCode() {
    const promoInput = document.getElementsByClassName('promo-input')[0];
    if (this.couponCode == "") {
      promoInput.classList.add('invalid')
      setTimeout(() => {
        promoInput.classList.remove('invalid')
      }, 1000);
      this.resetPrices()
      return;
    }
    const tempCode = this.couponCode;
    if (!this.authService.couponDeepLink) this.couponCode = ' . . .'
    this.isCouponCodeLoading = true;
    this.isCouponCodeInvalid = false;
    this.isCouponCodeValid = false;
    this.http.post(`${environment.api}/payments/check-promotion-code`, {
      code: tempCode,
      productId: this.product.id
    }, {
      headers: this.authService.authHeader
    }).subscribe((res: any) => {
      console.log(res)
      const promoInput = document.getElementsByClassName('promo-input')[0];
      if (res.valid) {
        this.isCouponCodeValid = true;
        this.couponObject = res;
        this.calcDiscount(res?.discount)
        this.priceNum = this.totalPrice.replace(/\D/g, "");
        if (!this.authService.couponDeepLink) promoInput.classList.add('valid')
        setTimeout(() => {
          if (!this.authService.couponDeepLink) promoInput.classList.remove('valid')
        }, 1000);
      } else {
        this.isCouponCodeInvalid = true;
        if (!this.authService.couponDeepLink) promoInput.classList.add('invalid')
        setTimeout(() => {
          if (!this.authService.couponDeepLink) promoInput.classList.remove('invalid')
        }, 1000);
        this.resetPrices()
      }
      this.isCouponCodeLoading = false;
      this.couponCode = tempCode;
    }), (error) => {
      console.log(error)
      this.isCouponCodeInvalid = false;
      this.isCouponCodeLoading = false;
    };
  }

  clearDiscount() {
    this.couponCode = '';
  }

  calcDiscount(percent: number) {
    if (this.purchaseService.webappSelectedPlan == 'monthly') this.totalPrice = this.webMonthlyPrice;
    if (this.purchaseService.webappSelectedPlan == 'yearly') this.totalPrice = this.webYearlyPrice;
    if (this.purchaseService.webappSelectedPlan == 'lifetime') this.totalPrice = this.webLifetimePrice;
    this.priceNum = this.totalPrice.replace(/\D/g, "");
    this.discount = this.priceNum * (percent / 100);
    this.tempPrice = this.priceNum - this.discount;
    this.discount = this.transformCurrencyAmountPipe.transform(this.discount);
    this.totalPrice = this.transformCurrencyAmountPipe.transform(this.tempPrice);
  }

  insertSpaceBeforeNumber(text: string) {
    let t = text;
    let index = t.search(/\d/);
    if (index > 0 && !/\s/.test(t[index - 1])) {
      t = t.slice(0, index) + ' ' + t.slice(index);
    }
    return t;
  }

  get currency(): string {
    return this.purchaseService.getCurrencySymbolFromCurrencyCode(this.purchaseService.getUserCurrency()?.toUpperCase())
  }

  // Returns the monthly price in the user's currency
  get webMonthlyPrice(): string {
    if (!this.isReady) return "";

    const prod: StripeProduct = this.purchaseService.webProductMonthly;
    let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
    return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
  }

  get webYearlyPrice(): string {
    if (!this.isReady) return "";

    const prod: StripeProduct = this.purchaseService.webProductYear;
    let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
    return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
  }
  get webLifetimePrice(): string {
    if (!this.isReady) return "";

    const prod: StripeProduct = this.purchaseService.webProductLifetime;
    let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
    return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
  }

  get promoPrice(): string {
    if (!this.isReady) return "";
    if (this.couponObject) {
      return this.totalPrice;
    }
    if (this.purchaseService.webappSelectedPlan == 'monthly') {

      const prod: StripeProduct = this.purchaseService.webProductMonthly;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

      if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
      this.tempPrice = localPrice.unit_amount;
      return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
    }
    if (this.purchaseService.webappSelectedPlan == 'yearly') {
      const prod: StripeProduct = this.purchaseService.webProductYear;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

      if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
      this.tempPrice = localPrice.unit_amount;
      return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
    }
    if (this.purchaseService.webappSelectedPlan == 'lifetime') {

      const prod: StripeProduct = this.purchaseService.webProductLifetime;
      let localPrice = prod.default_price.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

      if (!localPrice) localPrice = prod.default_price.currency_options['usd'];
      this.tempPrice = localPrice.unit_amount;
      return this.transformCurrencyAmountPipe.transform(localPrice.unit_amount);
    }
  }

  get labelPriceLifetime(): string {
    if (!this.isReady) return "";

    let label = this.languageService.label.web_payment_screen_toc_text;
    const prod: StripeProduct = this.purchaseService.webProductLifetime;

    let localPrice = prod?.default_price?.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod?.default_price?.currency_options['usd'];

    let price = this.transformCurrencyAmountPipe.transform(localPrice.unit_amount)
    if (this.couponObject) price = this.promoPrice;
    label = label?.replace("[price]", price);

    return label;
  }

  get labelPriceYearly(): string {
    if (!this.isReady) return "";
    let label;
    if (this.couponObject) label = this.languageService.label.web_payment_screen_toc_text_promo_yearly;
    else label = this.languageService.label.web_payment_screen_toc_text;
    const prod: StripeProduct = this.purchaseService.webProductYear;

    let localPrice = prod?.default_price?.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod?.default_price?.currency_options['usd'];
    let Promoprice = '';
    let price = this.transformCurrencyAmountPipe.transform(localPrice.unit_amount)
    if (this.couponObject) Promoprice = this.promoPrice;

    if (this.couponObject) {
      label = label?.replace("[price]", price);
      label = label?.replace("[Promoprice]", Promoprice);
    } else {
      label = label?.replace("[price]", price + this.languageService.label.txt_yearly_unit);
    }

    return label;
  }

  get labelPriceMonthly(): string {
    if (!this.isReady) return "";

    let label = this.languageService.label.web_payment_screen_toc_text_monthly;
    const prod: StripeProduct = this.purchaseService.webProductMonthly;

    let localPrice = prod?.default_price?.currency_options[this.purchaseService.getUserCurrency()?.toLowerCase()];

    if (!localPrice) localPrice = prod?.default_price?.currency_options['usd'];

    let price = this.transformCurrencyAmountPipe.transform(localPrice.unit_amount)
    if (this.couponObject) price = this.promoPrice;
    label = label?.replace("[price]", price + this.languageService.label.txt_monthly_unit);

    return label;
  }

  get YearTrialLabel(): string {
    let label = this.languageService.label.web_payment_screen_billed_yearly_trial
    label = this.creditCardPurchasePrice.replaceAnualPrice(label, this.promoPrice)
    const monthly = this.transformCurrencyAmountPipe.transform(this.tempPrice / 12)
    label = this.creditCardPurchasePrice.replaceMonthlyPrice(label, monthly)
    return label
  }

  get YearNoTrialLabel(): string {
    let label = this.languageService.label.web_payment_screen_billed_yearly_no_trial
    label = this.creditCardPurchasePrice.replaceAnualPrice(label, this.promoPrice)
    const monthly = this.transformCurrencyAmountPipe.transform(this.tempPrice / 12)
    label = this.creditCardPurchasePrice.replaceMonthlyPrice(label, monthly)
    if (this.purchaseService.skipTrial) {
      label = label.substring(0, label.indexOf('<br>'))
    }
    return label
  }

}
