<div class="title" [innerHTML]="languageService.label.txt_register_headline"></div>

<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
  <div class="control-item" [ngClass]="{'hide':isNarrow && !emailLogin}">
    <ion-item lines="none">
      <ion-input type="text" formControlName="name"
        placeholder="{{languageService.label.txt_register_placeholder_name}}"></ion-input>
      <!-- <p *ngIf="name.errors?.required && name.touched" class="error">Name is required.</p> -->
      <div *ngIf="name.touched && name.valid">
        <ion-icon class="input-icon-valid" name="checkmark-circle"></ion-icon>
      </div>

      <div *ngIf="name.touched && !name.valid">
        <ion-icon class="input-icon-invalid" name="close-circle"></ion-icon>
      </div>
    </ion-item>
  </div>

  <div class="control-item" [ngClass]="{'hide':isNarrow && !emailLogin}">
    <ion-item lines="none">
      <ion-input type="text" formControlName="email"
        placeholder="{{languageService.label.txt_register_placeholder_email}}"></ion-input>

      <div *ngIf="email.touched && email.valid">
        <ion-icon class="input-icon-valid" name="checkmark-circle"></ion-icon>
      </div>

      <div *ngIf="email.touched && !email.valid">
        <ion-icon class="input-icon-invalid" name="close-circle"></ion-icon>
      </div>

      <!-- <p *ngIf="email.errors?.required && email.touched" class="error">Email is required.</p>
        <p *ngIf="email.errors?.email && email.touched" class="error">Email format is invalid.</p> -->
    </ion-item>
  </div>

  <button type="submit" class="submit" [ngClass]="{'hide':isNarrow && !emailLogin}">
    <span *ngIf="!isPending">{{languageService.label.txt_register_cta}}</span>
    <ion-spinner *ngIf="isPending" name="crescent"></ion-spinner>
  </button>

  <p *ngIf="isError" class="info error">{{languageService.label.txt_register_message_error}}</p>

  <div *ngIf="false" class="sign-up-wrapper" (click)="goToLogin()"
    [innerHTML]="languageService.label.txt_register_cta_login"></div>


  <ng-container *ngIf="utilsService.isWebVersion">


    <div class="horizontal-line" [ngClass]="{'hide':spacingWeb && !this.isNarrow}"
      [ngClass]="{'hide':isNarrow && !emailLogin}">
      <div></div>
      <p>{{languageService.label.txt_login_divider_text}}</p>
      <div></div>
    </div>

    <div *ngIf="spacingWeb && !this.isNarrow" class="spacingRegister"></div>
    <div class="social-wrapper" *ngIf="isNarrow" [ngClass]="{'hide':isNarrow && emailLogin}">
      <div class="social-login-btn mail" (click)="loginMail()">
        <div>
          <ion-icon name="mail-outline"></ion-icon>
          <p>{{languageService.label.txt_login_mail}}</p>
        </div>
      </div>
    </div>

    <div class="social-wrapper" *ngIf="isNarrow">
      <a class="login-link" (click)="loginGoogle($event)">
        <div class="login-option-wrapper google">
          <div class="login-option">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90912H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7637 23.04 12.2614Z"
                fill="#4285F4" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.9999 23.4998C15.1049 23.4998 17.7081 22.47 19.6108 20.7137L15.8942 17.8282C14.8644 18.5182 13.5472 18.9259 11.9999 18.9259C9.00467 18.9259 6.46945 16.903 5.56513 14.1848H1.72308V17.1644C3.61536 20.9228 7.50445 23.4998 11.9999 23.4998Z"
                fill="#34A853" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z"
                fill="#FBBC05" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.9999 5.07386C13.6883 5.07386 15.2042 5.65409 16.396 6.79364L19.6944 3.49523C17.7029 1.63955 15.0997 0.5 11.9999 0.5C7.50445 0.5 3.61536 3.07705 1.72308 6.83545L5.56513 9.815C6.46945 7.09682 9.00468 5.07386 11.9999 5.07386Z"
                fill="#EA4335" />
            </svg>
            <p style="margin-left:5px;">{{languageService.label.txt_social_login_google}}</p>
          </div>
        </div>
      </a>
    </div>

    <div class="social-wrapper">
      <a class="login-link" (click)="loginGoogle($event)" *ngIf="!isNarrow">
        <div class="login-option-wrapper google">
          <div class="login-option">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90912H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7637 23.04 12.2614Z"
                fill="#4285F4" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.9999 23.4998C15.1049 23.4998 17.7081 22.47 19.6108 20.7137L15.8942 17.8282C14.8644 18.5182 13.5472 18.9259 11.9999 18.9259C9.00467 18.9259 6.46945 16.903 5.56513 14.1848H1.72308V17.1644C3.61536 20.9228 7.50445 23.4998 11.9999 23.4998Z"
                fill="#34A853" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z"
                fill="#FBBC05" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.9999 5.07386C13.6883 5.07386 15.2042 5.65409 16.396 6.79364L19.6944 3.49523C17.7029 1.63955 15.0997 0.5 11.9999 0.5C7.50445 0.5 3.61536 3.07705 1.72308 6.83545L5.56513 9.815C6.46945 7.09682 9.00468 5.07386 11.9999 5.07386Z"
                fill="#EA4335" />
            </svg>
          </div>
        </div>
      </a>
      <a class="login-link" (click)="loginFacebook($event)">
        <div class="login-option-wrapper facebook">
          <div class="login-option">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23 12.0699C23 5.7186 17.8513 0.56988 11.5 0.56988C5.14872 0.56988 0 5.7186 0 12.0699C0 17.8099 4.20538 22.5674 9.70312 23.4302V15.3941H6.7832V12.0699H9.70312V9.53629C9.70312 6.6541 11.42 5.06207 14.0468 5.06207C15.305 5.06207 16.6211 5.28668 16.6211 5.28668V8.11675H15.171C13.7424 8.11675 13.2969 9.00322 13.2969 9.91266V12.0699H16.4863L15.9765 15.3941H13.2969V23.4302C18.7946 22.5674 23 17.8099 23 12.0699Z"
                fill="white" />
            </svg>
            <p *ngIf="isNarrow" style="margin-left:5px;">{{languageService.label.txt_social_login_facebook}}</p>
          </div>
        </div>
      </a>
    </div>
    <p class="register-later" (click)="skipRegister()"
      *ngIf="onboardingService.isActive && !onboardingService.fromPromotion">

      {{languageService.label.txt_onboarding_register_cta_skip}}</p>
    <p class="subtle-text" [innerHTML]="languageService.label.web_login_legal_notice"></p>
  </ng-container>
  <div *ngIf="skipReg" class="overlayFinal" (click)="closeModal()"></div>
  <div *ngIf="skipReg" id="progressFinal">
    <div id="modal-approve-toc">
      <div>
        <div class="back-btn-modal" (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
        </div>
        <p><ion-checkbox (click)="addValue($event)"></ion-checkbox> <span
            [innerHTML]="languageService.label.txt_onboarding_register_approve_web"></span></p>
        <button [ngClass]="{'deactivated': !isChecked}"
          (click)="goHome()">{{languageService.label.txt_onboarding_register_approve_cta_web}}</button>
      </div>
    </div>
  </div>
</form>