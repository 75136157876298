<ion-content>

  <div class="content-wrapper">
    <div class="word-detail-wrapper">
      <div class="word-header">
        <div class="word-header-origin" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word.display }}
          <ion-icon name="volume-high-outline" (click)="playAudio()"></ion-icon>
        </div>
        <div class="word-header-pronounciation" *ngIf="languageService.origin =='ja'"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          <span *ngIf="word.hiragana && this.settingsService.showHiragana"> {{ word |
            wordArticle }} {{ word.hiragana }} </span>
          <span *ngIf="word.romanji && this.settingsService.showRomaji">
            ({{ word.romanji
            }})
          </span>
        </div>
        <div class="word-header-pronounciation" *ngIf="languageService.origin =='zh-hans' && (word.pinyin) "
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word | wordArticle }} ({{ word.pinyin }})
        </div>
        <div *ngIf="!wordService.hideTranslation" class="word-header-translation"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word | wordTranslation }}
        </div>
      </div>
      <div class="word-tense-wrapper" *ngIf="word.category == 'adjectives' && languageService.origin == 'fr'">
        <ng-container *ngFor="let set of word.forms">
          <div class="word-form-title" *ngIf="set.type == 'masculineSingular'"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{this.languageService.label.txt_masculine}}
          </div>
        </ng-container>
        <ng-container *ngFor="let set of word.forms">
          <ng-container *ngIf="set.text && (set.type == 'masculineSingular' || set.type == 'masculinePlural')">
            <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div class="persona" *ngIf="set.type == 'masculineSingular'">
                {{this.languageService.label.txt_singular}}
              </div>
              <div class="persona" *ngIf="set.type == 'masculinePlural'">
                {{this.languageService.label.txt_plural}}
              </div>
              <div class="adj-gender">
                {{set.text}}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="word-tense-wrapper" *ngIf="word.category == 'adjectives' && languageService.origin == 'fr'">
        <ng-container *ngFor="let set of word.forms">
          <div class="word-form-title" *ngIf="set.type == 'feminineSingular'"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{this.languageService.label.txt_feminine}}
          </div>
        </ng-container>

        <ng-container *ngFor="let set of word.forms">
          <ng-container *ngIf="set.text &&  (set.type == 'feminineSingular' || set.type == 'femininePlural')">
            <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'feminineSingular'">
                {{this.languageService.label.txt_singular}}
              </div>
              <div style="min-width: 65px;" class="persona" *ngIf="set.type == 'femininePlural'">
                {{this.languageService.label.txt_plural}}
              </div>
              <div class="adj-gender">
                {{set.text}}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="word-tense-wrapper" *ngIf="word.category == 'adjectives' && languageService.origin == 'es'">
        <ng-container *ngFor="let set of word.forms">
          <div class="word-form-title" *ngIf="set.gender == 'masculine' && set.type == 'singular'"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{this.languageService.label.txt_masculine}}
          </div>
        </ng-container>
        <ng-container *ngFor="let set of word.forms">
          <ng-container *ngIf="set.text && set.gender == 'masculine'">
            <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div class="persona" *ngIf="set.type == 'singular'">
                {{this.languageService.label.txt_singular}}
              </div>
              <div class="persona" *ngIf="set.type == 'plural'">
                {{this.languageService.label.txt_plural}}
              </div>
              <div class="adj-gender">
                {{set.text}}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="word-tense-wrapper" *ngIf="word.category == 'adjectives' && languageService.origin == 'es'">
        <ng-container *ngFor="let set of word.forms">
          <div *ngIf="set.gender == 'feminine'">
            <div class="word-form-title" *ngIf="set.gender == 'feminine' && set.type == 'singular'"
              [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{this.languageService.label.txt_feminine}}
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let set of word.forms">
          <ng-container *ngIf="set.text && set.gender == 'feminine'">
            <div class="word-form-content-row row-small" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <div class="persona" *ngIf="set.type == 'singular'">
                {{this.languageService.label.txt_singular}}
              </div>
              <div class="persona" *ngIf="set.type == 'plural'">
                {{this.languageService.label.txt_plural}}
              </div>
              <div class="adj-gender">
                {{set.text}}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="word.category == 'adjectives' && languageService.origin == 'ja'">
        <div class="word-tense-wrapper" *ngIf="adjPlainForm.length > 0">
          <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_ja_adj_plain_form}}
          </div>
          <div class="word-form-content conjugate-container" *ngFor="let set of adjPlainForm"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <ng-container>
              <div class="word-form-content-single ">
                <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                  </span>{{set.kanji}}
                  <span class="romaji" *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="word-tense-wrapper" *ngIf="pastForm.length > 0">
          <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_ja_adj_past_tense}}
          </div>
          <div class="word-form-content conjugate-container" *ngFor="let set of pastForm"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <ng-container>
              <div class="word-form-content-single ">
                <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                  </span>{{set.kanji}}
                  <span class="romaji" *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="word-tense-wrapper" *ngIf="negativeForm.length > 0">
          <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_ja_adj_negative_form}}
          </div>
          <div class="word-form-content conjugate-container" *ngFor="let set of negativeForm"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <ng-container>
              <div class="word-form-content-single ">
                <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                  </span>{{set.kanji}}
                  <span class="romaji" *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="word-tense-wrapper" *ngIf="attributiveForm.length > 0">
          <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_ja_adj_attributive_form}}
          </div>
          <div class="word-form-content conjugate-container" *ngFor="let set of attributiveForm"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <ng-container>
              <div class="word-form-content-single ">
                <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                  </span>{{set.kanji}}
                  <span class="romaji" *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="word-tense-wrapper" *ngIf="subjunctiveForm.length > 0">
          <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_ja_adj_subjunctive_form}}
          </div>
          <div class="word-form-content conjugate-container" *ngFor="let set of subjunctiveForm"
            [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <ng-container>
              <div class="word-form-content-single">
                <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                  </span>{{set.kanji}}
                  <span class="romaji" *ngIf="this.settingsService.showRomaji"><br>({{set.romaji}})</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="word.category == 'verb'">
        <ng-container *ngFor="let set of word.tenses">
          <div class="word-tense-wrapper" *ngIf="set.display">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{ set.tense }}
            </div>
            <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

              <ng-container *ngIf="set.text">
                <div style="min-width: 65px;">
                  {{set.text}}
                </div>
              </ng-container>

              <ng-container *ngFor="let form of set.forms, let i = index">
                <ng-container *ngIf="i < 7">
                  <div class="word-form-content-row" *ngIf="form.persona"
                    [ngClass]="{'row-small': !form.aux,'biggerFont': this.wordService.enlargeFont}">
                    <div class="persona">{{form.persona}}</div>
                    <div *ngIf="form.aux" style="min-width: 65px;">
                      {{form.aux}}
                    </div>
                    <div class="right-edge">{{form.text}}<span
                        *ngIf="suffix && (set.tense== word.tenses[0].tense || set.tense == word.tenses[1].tense) && this.languageService.origin == 'de'">
                        {{suffix}}</span></div>
                  </div>
                </ng-container>

                <div class="word-form-content-single" *ngIf="!form.persona" [ngClass]="{'row-small': form.aux}">
                  <div *ngIf="form.aux && languageService.origin == 'de'"
                    style="min-width: 65px;color: var(--word-detail-persona-font-color)">
                    {{form.aux}}
                  </div>
                  <div>{{form.text}}</div>
                </div>

              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="indicative && this.languageService.origin == 'es' ">
          <div class="verb-mode-title word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_es_verb_indicative_form}}</div>
          <ng-container *ngFor="let set of wordTensesIndicative">
            <div class="word-tense-wrapper" *ngIf="set.display">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                {{ set.tense }}
              </div>
              <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

                <ng-container *ngIf="set.text">
                  <div style="min-width: 65px;">
                    {{set.text}}
                  </div>
                </ng-container>

                <ng-container *ngFor="let form of set.forms, let i = index">
                  <ng-container *ngIf="i < 7">
                    <div class="word-form-content-row" *ngIf="form.persona"
                      [ngClass]="{'row-small': !form.aux, 'biggerFont': this.wordService.enlargeFont}">
                      <div class="persona">{{form.persona}}</div>
                      <div *ngIf="form.aux" style="min-width: 65px;">
                        {{form.aux}}
                      </div>
                      <div class="right-edge">{{form.text}}</div>
                    </div>
                  </ng-container>

                  <div class="word-form-content-single" *ngIf="!form.persona" [ngClass]="{'row-small': form.aux}">
                    <div>{{form.text}}</div>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="subjunctive && languageService.origin == 'es' ">
          <div class="verb-mode-title word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{languageService.label.txt_es_verb_subjunctive_form}}</div>
          <ng-container *ngFor="let set of wordTensesSubjunctive">
            <div class="word-tense-wrapper" *ngIf="set.display">
              <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                {{ set.tense }}
              </div>
              <div class="word-form-content" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">

                <ng-container *ngIf="set.text">
                  <div style="min-width: 65px;">
                    {{set.text}}
                  </div>
                </ng-container>

                <ng-container *ngFor="let form of set.forms, let i = index">
                  <ng-container *ngIf="i < 7">
                    <div class="word-form-content-row" *ngIf="form.persona"
                      [ngClass]="{'row-small': !form.aux, 'biggerFont': this.wordService.enlargeFont}">
                      <div class="persona">{{form.persona}}</div>
                      <div *ngIf="form.aux" style="min-width: 65px;">
                        {{form.aux}}
                      </div>
                      <div class="right-edge">{{form.text}}</div>
                    </div>
                  </ng-container>

                  <div class="word-form-content-single" *ngIf="!form.persona" [ngClass]="{'row-small': form.aux}">
                    <div>{{form.text}}</div>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="languageService.origin == 'ja'">
          <!-- verb -->
          <div class="word-tense-wrapper" *ngIf="plainForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_plain}}
            </div>
            <div class="word-form-content conjugate-container" *ngFor="let set of plainForms"
              [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <ng-container>
                <div class="word-form-content-row row-small left-verb"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                  <div class="persona">{{set | JAWordCategoryLabel}}</div>
                  <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<br><span class="romaji"><span
                        *ngIf="this.settingsService.showRomaji">({{set.romaji}})</span></span></div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="word-tense-wrapper" *ngIf="politeForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_polite}}
            </div>
            <div class="word-form-content conjugate-container" *ngFor="let set of politeForms"
              [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <ng-container>
                <div class="word-form-content-row row-small left-verb"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                  <div class="persona">{{set | JAWordCategoryLabel}}</div>
                  <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<br>
                    <span class="romaji" *ngIf="this.settingsService.showRomaji">({{set.romaji}})</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="word-tense-wrapper" *ngIf="otherForms.length > 0">
            <div class="word-form-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              {{languageService.label.txt_japanese_form_other}}
            </div>
            <div class="word-form-content conjugate-container" *ngFor="let set of otherForms"
              [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
              <ng-container>
                <div class="word-form-content-row row-small left-verb"
                  [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
                  <div class="persona">{{set | JAWordCategoryLabel}}</div>
                  <div><span *ngIf="this.settingsService.showHiragana && set.hiragana != ''" class="hiragana"
                      [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{set.hiragana}}
                    </span>{{set.kanji}}<br><span class="romaji"><span
                        *ngIf="this.settingsService.showRomaji">({{set.romaji}})</span></span></div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>


  </div>


  <div class="control-wrapper">
    <div class="close-btn" (click)="closeModal()">
      {{languageService.label.btn_word_detail_close}}
    </div>
  </div>

</ion-content>