import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '@services/auth-service/auth.service';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { environment } from 'environments/environment';
import { timer } from 'rxjs';
import { Location } from '@angular/common';
import OneSignal from 'onesignal-cordova-plugin';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NotificationService } from '@services/notification-service/notification.service';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { Device } from '@capacitor/core';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent {

  @Input() closeForm;
  @Output() changeSignOption = new EventEmitter<boolean>();
  @Output('onShowVerifyCode') showVerifyCode = new EventEmitter<boolean>();
  @Output('onSuccessfulLogin') successfulLogin = new EventEmitter<any>();

  public signupForm: FormGroup;
  isShowPassword = false;
  isPending: boolean = false;
  isError: boolean = false;
  signupMethod: string;
  isNarrow = false;

  isChecked = false;
  skipReg = false;
  checked: boolean = false;
  spacingWeb = false;
  emailLogin = false;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    public languageService: LanguageService,
    private firebaseAnalytics: FirebaseAnalytics,
    public utilsService: UtilsService,
    private gtmService: GoogleTagManagerService,
    public notificationService: NotificationService,
    private alertController: AlertController,
    private purchaseService: PurchaseService,
    private storageService: StorageService,
    private location: Location,
    public onboardingService: OnboardingService,
    private navCtrl: NavController,
    private platform: Platform,
  ) {
    this.signupForm = this.formBuilder.group(
      {
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        // password: ['', {
        //   validators: [
        //     Validators.required,
        //     Validators.minLength(8),
        //     Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])([a-zA-Z0-9\W]+)/)
        //   ],
        //   updateOn: 'change'
        // }]
      },
    );

    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.type === 'loginSuccessful') {
        this.handleSuccessfulLogin(event.data.user, event.data.token);
        this.authService.addUserDevice();
      } else if (event.data.type === 'loginError') {
        console.log("Login error", event);
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1000) {
      this.isNarrow = true;
    } else {
      this.isNarrow = false;
    }
  }
  ngOnInit() {
    const currentUrl = this.location.path();
    if (window.innerWidth < 700) {
      this.isNarrow = true;
    } else {
      this.isNarrow = false;
    }
    if (this.utilsService.isWebVersion && currentUrl && currentUrl.includes('home')) {
      this.spacingWeb = true;
    }
  }

  loginMail() {
    this.emailLogin = true;
  }

  get name() {
    return this.signupForm.get('name');
  }

  get email() {
    return this.signupForm.get('email');
  }

  get password() {
    return this.signupForm.get('password');
  }

  goToLogin() {
    this.changeSignOption.emit(true);
  }

  skipRegister() {
    this.skipReg = true;
    timer(300).subscribe(() => {
      document.getElementById('modal-approve-toc').classList.add('visible');
    })
  }

  closeModal() {
    this.skipReg = false;
  }

  goHome() {
    if (!this.isChecked) return
    this.skipReg = false;
    this.navCtrl.navigateForward('/home');
  }

  addValue(e): void {
    this.isChecked = !e.currentTarget.checked;
    console.log(this.isChecked);//it is working !!!
  }

  async onSubmit() {
    this.isPending = true;
    this.signupForm.markAllAsTouched();

    if (this.signupForm.invalid) {
      this.isPending = false;
      return;
    }

    if (this.signupForm.invalid) return;

    const userData = this.signupForm.getRawValue();
    userData.userLanguage = this.languageService.target;
    userData.userId = this.authService.anonymousUserId;
    if (this.platform.is('cordova')) {
      userData.registerOrigin = 'app';
      if (userData.email) OneSignal.User.addEmail(userData.email);
      if (userData.userId) OneSignal.login(userData.userId);
    } else {
      userData.registerOrigin = 'web';
      this.signupMethod = "email";
      const gtmTag = {
        event: 'click_cta_create_account',
        category: 'signup initiated',
        action: this.signupMethod,
      }
      this.gtmService.pushTag(gtmTag);
    }

    if (environment.single) {
      userData.slaOrigin = environment.language;
    }
    this.http.post(`${environment.api}/user/register/app`, userData).subscribe(res => {
      this.authService.user = {
        email: this.signupForm.getRawValue().email
      };
      timer(1000).subscribe(() => {
        this.isPending = false;
        this.isError = true;
        this.showVerifyCode.emit(true);
        this.firebaseAnalytics.setCurrentScreen('screen_verify_email');
      });
    }, async (error) => {
      this.http.post(`${environment.api}/user/login`, {
        username: this.signupForm.getRawValue().email,
        deviceInfo: await Device.getInfo()
      }).subscribe(res => {
        this.authService.user = {
          email: this.signupForm.getRawValue().email
        };
        timer(1000).subscribe(() => {
          this.isPending = false;
          this.isError = true;
          this.showVerifyCode.emit(true);
          this.firebaseAnalytics.setCurrentScreen('screen_verify_email');
        });
      }, (error) => {
        this.isError = true;
        this.isPending = false;
      })

    });
  }

  loginGoogle(event) {
    this.signupMethod = "google-sso";
    const gtmTag = {
      event: 'click_cta_create_account',
      category: 'signup initiated',
      action: this.signupMethod,
    }
    this.gtmService.pushTag(gtmTag);

    // stop all other events
    event.preventDefault();

    // open google login in new brower window
    const url = `https://production.langster-back.com/api/auth/google`;
    const name = 'Google Login';

    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);

    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  loginFacebook(event) {
    this.signupMethod = "facebook-sso";
    const gtmTag = {
      event: 'click_cta_create_account',
      category: 'signup initiated',
      action: this.signupMethod,
    }
    this.gtmService.pushTag(gtmTag);

    // stop all other events
    event.preventDefault();


    // open google login in new brower window
    const url = `https://production.langster-back.com/api/auth/facebook`;
    const name = 'Facebook Login';
    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);
    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  loginApple(event) {
    this.signupMethod = "apple-sso";
    const gtmTag = {
      event: 'click_cta_create_account',
      category: 'signup initiated',
      action: this.signupMethod,
    }
    this.gtmService.pushTag(gtmTag);

    // stop all other events
    event.preventDefault();

    // open google login in new brower window
    const url = `https://production.langster-back.com/api/auth/apple/web`;
    const name = 'Apple Login';
    // specify the desired popup dimensions
    const width = 600;
    const height = 600;

    // calculate the position of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    // open the popup window with the specified dimensions and position
    const options = `height=${height},width=${width},left=${left},top=${top}`;

    const popup = window.open(url, name, options);
    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  }

  showAlertToManyDevices(tmpToken: string) {
    this.alertController.create({
      header: this.languageService.label.txt_device_limit_reached_title,
      message: this.languageService.label.txt_device_limit_reached_text,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_yes,
          role: 'confirm',
          cssClass: 'secondary',
          handler: () => {
            this.authService.removeOldestDevice(tmpToken).then((data) => {
              this.showRemoveOldestDeviceSuccessAlert();
            });
          }
        },
        {
          text: this.languageService.label.txt_device_limit_reached_no,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  async showRemoveOldestDeviceSuccessAlert() {
    this.alertController.create({
      message: this.languageService.label.txt_device_limit_reached_logout_success,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_logout_success_confirm,
          handler: () => {
            // this.logout();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    })
  }

  // Will only be called for web login
  async handleSuccessfulLogin(user, token) {
    console.log("handleSuccessfulLogin is called")

    this.authService.setToken(token);
    this.authService.setUser(user);

    if (!user.userId) {
      await this.authService.storeUserData({ userId: this.authService.anonymousUserId });
      const u = user;
      u.userId = this.authService.anonymousUserId;
      this.authService.setUser(u);
    }

    this.authService.trackAccountCreated();
    setTimeout(async () => {
      if (this.authService.newAccount) {
        await this.storageService.storeInitUserDataOnServer().then(async () => {

          if (!user.data || user.data?.length == 0 || (user.data && !user.data[0].training)) {
            await this.storageService.importTrainingDataToServer();
          }

          await this.storageService.initUserDataFromServer();
          this.authService.loadUserData();
        });

      } this.authService.newAccount = false;
    }, 500);
    if (this.utilsService.isWebVersion) this.notificationService.OneSignalWeb();
    if (this.onboardingService.fromPromotion) this.navCtrl.navigateForward(this.onboardingService.promotionUrl);

    this.purchaseService.getUserStripeData();
    this.purchaseService.getRevenueCatSubscription();
    this.storageService.sendRevenueCatAttributes();
    // Store inital user data in the database
    // if the user has no data in the database yet
    if (!user.selectedLanguage && !user.selectedLevel) {
      const selectedLanguage = this.languageService.origin;
      const selectedLevel = this.storageService.userLevel;
      this.authService.storeUserData({ selectedLanguage, selectedLevel }).then((res => { }), (err) => { });
    } else {
      // React to a user that has already an account with data in the account
      const selectedLanguage = this.languageService.origin;
      const selectedLevel = this.storageService.userLevel;

      // Will change the learning language if the user
      // has a different one as selected in the account
      if (selectedLanguage != user.selectedLanguage) {
        this.languageService.setOrigin(user.selectedLanguage, false);
      }

      // Will change the level for the selected learning language 
      // if the user has a different one as selected in the account
      if (selectedLevel != user.selectedLevel) {
        this.storageService.storeUserLevel(user.selectedLevel, false);
        this.firebaseAnalytics.setUserProperty("level", user.selectedLevel);
      }

    }

    // Load complete user data after successful login and store data in DB with initUserDatraFromServer() 
    this.authService.loadCompleteUserData(this.languageService.origin).then(async user => {
      this.authService.setUser(user);
      this.purchaseService.checkStripeSubscription(user);
      // Will check current localhost and store it in the database if necessary
      if (!user.data || user.data?.length == 0 || (user.data && !user.data[0].training)) {
        await this.storageService.importTrainingDataToServer();
      }
      this.storageService.initUserDataFromServer();

      // store any changes to the data in the database
      this.authService.storeUserData({
        selectedLanguage: this.languageService.origin,
        selectedLevel: this.storageService.userLevel,
        userLanguage: this.languageService.target,
      });
    })

    console.log("debug_1")
    this.firebaseAnalytics.setUserProperty("loggedin", 'true');

    this.successfulLogin.emit();
  }

}