import { Injectable } from '@angular/core';
import { TrainingStack, TrainingWord } from '../../interfaces/training';
import Word from '@interfaces/word';
import { DialogueContent, WordResultMap } from '@interfaces/story';
import { Storage } from '@ionic/storage';
import { StoryService } from '@services/story-service/story.service';
import { LanguageService } from '@services/language-service/language.service';

@Injectable({
  providedIn: 'root'
})
export class MemorizeService {

  public stack: TrainingStack = {
    words: []
  } as TrainingStack;

  public stackToday: TrainingWord[] = [];

  public hideTrainingBtn = false;
  public trainingWord: TrainingWord = {} as TrainingWord;
  public wordData: Word = {} as Word;
  public evaluated = false;
  public back = false;
  public storyID: any;
  public showTrainingHelp = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    public storage: Storage,
    public storyService: StoryService,
    public languageService: LanguageService
  ) {
    this.resetStack();
  }
  public resetStack(): void {
    this.stack = {
      words: []
    } as TrainingStack;

    this.stackToday = [];
  }

  public async getRandomWord(): Promise<TrainingWord> {
    const word = this.stackToday.shift();
    this.trainingWord = word;
    if (word?.dialogueContent && word.dialogueContent?.rawText == '1') {
      await this.storyService.getDialogueStoryById(word.storyId, this.languageService.origin).subscribe(async dialogueData => {
        this.trainingWord.dialogueContent = dialogueData.content[word.contentIndex];
      })
    }
    return this.trainingWord;

  }

  public shuffleStackToday(): void {
    this.stackToday = this.shuffle(this.stackToday);
  }

  private shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  public addToStack(id: string, sentence?: WordResultMap[], position?: number, storyId?: string, word?: Word, totalPosition?: number, dialogueContent: DialogueContent = null, dialogueIndex: number = null): TrainingWord {

    const trainingWord = {} as TrainingWord;
    trainingWord.id = id;

    if (sentence) trainingWord.sentence = sentence;
    if (position) trainingWord.position = position || 0;
    if (storyId) trainingWord.storyId = storyId;
    if (word) trainingWord.word = word;
    if (totalPosition) trainingWord.totalPosition = totalPosition || 0
    if (dialogueContent) trainingWord.dialogueContent = dialogueContent;
    if (dialogueIndex) trainingWord.contentIndex = dialogueIndex;
    if (dialogueIndex == 0) trainingWord.contentIndex = 0;
    console.log('added', trainingWord, dialogueIndex, dialogueContent)
    this.stack.words.push(trainingWord);
    this.stackToday.push(trainingWord);
    return trainingWord;
  }

}
