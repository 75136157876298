<div *ngIf="!isSubmitted">
  <div class="title" [innerHTML]="languageService.label.txt_report_error_title"></div>
  <div class="translate-wrapper" *ngIf="word">
    <div class="content" [ngClass]="{'fill': word.explanation}">
      <div class="singular-container">
        <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{ word | wordArticle }} {{ word.display }}<span
            *ngIf="(languageService.origin == 'en' || languageService.origin == 'de' )&& word | wordForm: 'comparative'">,
            {{ word | wordForm: 'comparative'}}</span>
          <span
            *ngIf="(languageService.origin == 'en' || languageService.origin == 'de' )&& word | wordForm: 'superlative'">,
            {{ word | wordForm: 'superlative'}}</span>
          <ion-icon name="volume-high-outline" (click)="playAudio()"></ion-icon>
        </div>
        <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
          *ngIf="languageService.origin == 'ja' && (this.settingsService.showHiragana || this.settingsService.showRomaji)">
          <span *ngIf="this.settingsService.showHiragana">{{ word | wordArticle }} {{ word.hiragana }}</span> <span
            *ngIf="this.settingsService.showRomaji">({{word.romanji}})</span>
        </div>
        <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
          *ngIf="languageService.origin == 'zh-hans' && word.pinyin">
          {{ word | wordArticle }} ({{ word.pinyin }})
        </div>
      </div>

      <div *ngIf="(word | wordForm: 'plural') && !isAdjective" class="plural-container">
        <div class="keyword-plural" style="margin-bottom: 10px;" [ngClass]="{'keyword-plural-small': UI_word_small}">
          <div class="keyword-plural-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            <span *ngIf="languageService.origin === 'de'">die</span>
            {{ word | wordForm: 'plural'}}
          </div>
          <div class="keyword-plural-label" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
            {{this.languageService.label.txt_plural}}</div>
        </div>
      </div>

      <div class="translation" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordTranslation }}
      </div>
      <div class="definition" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordExplaination }}
      </div>
    </div>
  </div>
  <form [formGroup]="errorReportForm">
    <ion-radio-group value="translation" (ionChange)="changed($event)" [allowEmptySelection]="false">
      <div class="report-select">
        <ion-radio mode="md" slot="end" value="audio" labelPlacement="end"></ion-radio>
        <ion-label>{{ languageService.label.txt_report_error_audio }}</ion-label>
      </div>
      <div class="report-select">
        <ion-radio mode="md" slot="end" value="meaning" labelPlacement="end"></ion-radio>
        <ion-label>{{ languageService.label.txt_report_error_meaning }}</ion-label>
        <div class="description">{{ replaceWord() }}</div>
      </div>
      <div class="report-select">
        <ion-radio mode="md" slot="end" value="translation" labelPlacement="end"></ion-radio>
        <ion-label>{{ languageService.label.txt_report_error_trans }}</ion-label>
        <div class="description inputText">
          <ion-input type="text" formControlName="textInput"
            placeholder="{{languageService.label.txt_report_error_trans_text}}" maxlength="50" counter="true"
            (ngModelChange)="check()"></ion-input>
        </div>
        <ion-label class="counter">{{ countChar }}/50</ion-label>
      </div>
    </ion-radio-group>
    <button [disabled]="!isChecked" [ngClass]="{'deactivated': !isChecked}"
      (click)="submit()">{{languageService.label.txt_report_error_cta}}</button>
  </form>
</div>

<div *ngIf="isSubmitted">
  <h3 class="title">{{languageService.label.txt_report_error_thanks_title}}</h3>
  <p class="content" [innerHTML]="languageService.label.txt_report_error_thanks_text"></p>
  <button (click)="closeModal()">{{languageService.label.txt_report_error_thanks_cta}}</button>
</div>