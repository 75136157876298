import { Dialogue, DialogueContent, WordResultMap } from "./story";
import Word from "./word";

export interface TrainingStack {
    userId?: string,
    words: TrainingWord[]
}

export interface TrainingDeck {
    userId?: string,
    language?: string,
    name?: string,
    description?: string,
    cards: DeckCard[],
    _id: string
}

export interface DeckCard {
    id: string;
    category?: string,
    contentType?: string,
    word?: Word,
    dueDate: Date,
    efactor?: number,
    interval?: number,
    intervalBeforeLapse?: number,
    intervalAgain?: number,
    intervalEasy?: number,
    intervalGood?: number,
    intervalHard?: number,
    state?: string,
    storyId?: string,
    shortStoryId?: string,
    dialogueId?: string,
    wordId?: string,
    position?: number,
    Totalposition?: number,
    totalPosition?: number,
    contentPosition?: number,
    _id: string
}

export interface TrainingDeckSummary {
    again?: number,
    hard?: number,
    good?: number,
    easy?: number,
    new?: number,
    due?: number,
}

export interface TrainingWord {
    id: string;
    difficulty?: string,
    show: Date,
    sentence?: WordResultMap[],
    position?: number,
    storyId?: string,
    word?: Word,
    totalPosition?: number,
    explaination?: string,
    dialogueContent?: DialogueContent,
    dialogue?: Dialogue,
    contentIndex?: number,
    contentType?: string,
    shortStoryId?: string,
    dialogueId?: string,
    contentPosition?: number,
    _id: string
}

export enum TrainingDifficulty {
    done = 'DONE',
    hard = 'HARD',
    easy = 'EASY',
    good = 'GOOD'
}