<div class="wrapper">

  <div [ngClass]="{'hide': isSuccess}">
    <h1 class="title">{{languageService.label.txt_verify_headline}}</h1>
    <div class="description">{{languageService.label.txt_verify_text}}</div>

    <form class="content-area">
      <div style="display: flex; justify-content: center;">
        <input type="number" name='code' class='code-input' required />
        <input type="number" name='code' class='code-input' required />
        <input type="number" name='code' class='code-input' required />
        <input type="number" name='code' class='code-input' required />
        <input type="number" name='code' class='code-input' required />
        <input type="number" name='code' class='code-input' required />
      </div>

      <p class="error" [ngClass]="{'active': isError}">{{languageService.label.txt_verify_message_error}}</p>

      <button (click)="onSubmit($event)">
        <span *ngIf="!isPending">{{languageService.label.txt_verify_cta}}</span>
        <ion-spinner *ngIf="isPending" name="crescent"></ion-spinner>
      </button>
      <div class="resend-wrapper">
        <div>
          <ion-icon *ngIf="isResendSuccess" style="margin-right: 4px;" name="checkmark-circle"></ion-icon><span
            *ngIf="isResendSuccess" style="margin-right: 4px;">{{languageService.label.txt_verify_resend_success}}
          </span>
        </div>
        <a class="inline-span" *ngIf="!isResendDisabled" (click)="resendVerificationCode()">
          <span>{{languageService.label.txt_verify_resend}}</span>
        </a>
        <div *ngIf="isResendError">{{languageService.label.txt_verify_resend_error}}</div>
        <div *ngIf="isResendDisabled">{{languageService.label.txt_verify_resend_disabled}} (00:{{timer}})</div>
      </div>
      <div class="resend-wrapper">
        <a class="inline-span" (click)="goBack()">
          <span>{{this.languageService.label.txt_verify_use_other_email}}</span>
        </a>
      </div>

    </form>
  </div>

  <ng-container *ngIf="isSuccess">
    <h1 class="title" *ngIf="authService.newAccount">{{languageService.label.txt_verify_success_headline}}</h1>
    <h1 class="title" *ngIf="!authService.newAccount" [innerHTML]="WelcomeMsg()"></h1>

    <div class="description" *ngIf="authService.newAccount">{{languageService.label.txt_verify_success_text}}</div>
    <div class="description" *ngIf="!authService.newAccount">{{txt_login_modal_success_text_alt}}</div>
    <div class="content-area">
      <button (click)="continue()">
        <span>{{languageService.label.txt_verify_success_cta}}</span>
      </button>
    </div>
  </ng-container>


</div>