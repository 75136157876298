<div class="cta-memorize" *ngIf="isLandscape">
  <div class="btn-memorize" (click)="Memorize()"><ion-icon name="file-tray-full"></ion-icon> <span
      class="memorize-text">{{languageService.label.btn_keyword_flashcards_enter}}</span> <ion-icon
      name="arrow-forward-outline"></ion-icon></div>
</div>

<ng-container *ngFor="let key of keywords; let i = index;">
  <div class="karte karte-keyword" *ngIf="key.keyword && key.result">
    <div class="keyword-wrapper">
      <p class="keyword-display" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        <span class="single-element-level" [ngClass]="key.result?.level"
          *ngIf="key.result?.level && !(key.result?.level == '-' || key.result?.level.toLowerCase() == 'other' )">{{key.result?.level
          }}</span>
        {{key.result | wordArticle}} {{ key.result?.display }}<span *ngIf="key.result | wordForm: 'comparative'">, {{
          key.result | wordForm: 'comparative'}}</span>
        <span *ngIf="key.result | wordForm: 'superlative'">, {{ key.result | wordForm: 'superlative'}}</span><span
          class="category-name" *ngIf="key.result?.category && key.result?.category != 'not defined'"> -
          {{key.result?.category | wordCategoryLabel}}</span>
      </p>
      <span class="pronounce" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
        *ngIf="(this.settingsService.showRomaji || this.settingsService.showHiragana) && this.languageService.origin =='ja'">
        <span *ngIf=" key.result?.hiragana !='' && this.settingsService.showHiragana"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{
          word | wordArticle }} {{ key.result?.hiragana }}
        </span>
        <span *ngIf="this.settingsService.showRomaji">({{key.result.romanji}})</span></span>
      <span class="pronounce" [ngClass]="{'biggerFont': this.wordService.enlargeFont}" *ngIf="key.result?.pinyin"> {{
        word | wordArticle }} ({{ key.result?.pinyin }})</span>
      <p class="keyword-translation"
        [ngClass]="{'biggerFont': this.wordService.enlargeFont, 'web-content' : isLandscape}">
        {{ key.result | wordTranslation }}
      </p>
      <p class="definition" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ key.result | wordExplaination }}
      </p>

    </div>
    <div class="keywords-btn-container">
      <button style="margin-right:0px;" class="keyword-btn-icon" (click)="toggleTraining(key, i)"
        [ngClass]="{ 'keyword-training-active': key.result.inTraining }">
        <ion-icon name="barbell-outline"></ion-icon>
      </button>
      <button class="keyword-btn-icon audio-btn" (click)="playAudio(i, $event)">
        <ion-icon name="play"></ion-icon>
      </button>
    </div>
  </div>
</ng-container>